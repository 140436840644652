import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";

const Map = ({ maps }) => {
  const [map, set_map] = useState(null);

  useEffect(() => {
    try {
      set_map(JSON.parse(maps));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [maps]);

  const fleetDistributionLayout = {
    geo: {
      showframe: false,
      showcoastlines: false,
      coastlinecolor: "black",
      showland: true,
      landcolor: "whitesmoke",
      center: { lon: 10, lat: 51 }, // Center of Germany
      projection: {
        type: "mercator", // Map projection type
      },
      lataxis: {
        range: [30, 75], // Expanded latitude range for a slightly zoomed-out view
      },
      lonaxis: {
        range: [2, 60], // Expanded longitude range
      },
    },
    margin: { t: 15, b: 15, l: 15, r: 30 },
    dragmode: "zoom", // Enables zooming
  };
  
  
  

  return (
    <div style={{ width: '100%', height: '100%', boxSizing: 'border-box', marginTop:'10px', overflow: 'hidden'  }}>
      {/* <h4 style={{'color':'black'}}>Fleet distribution</h4> */}

      <Plot
  data={map?.data?.map(trace => ({
    ...trace,
    showscale: false, // Hide the color scale
    colorbar: { showticklabels: false }, // Remove tick labels if the color bar still appears
  }))}
  layout={{
    ...fleetDistributionLayout,
    autosize: true,
    responsive: true,
    coloraxis: undefined, // Remove any global color axis
  }}
  style={{
    width: "100%",
    height: "470px",
  }}
  useResizeHandler={true}
/>


  </div>
  

  );
};

Map.propTypes = {
  maps: PropTypes.string.isRequired,
};

export default Map;
