import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Restored module imports (ensure these files exist in your project)
import ifav from '../assets/images/ifav.png';
import Map from './Summary/Map';
import Mileage from './Summary/Mileage';
import Charging_cycle from './Summary/Charging_cycles';
import DynamicTable from './Summary/Table';
import { useGraphContext } from '../GraphContext';

// Define the plot color to be used by both the strip plot and the choropleth plot
const plotColor = "#CC0000";

// ======================================================================
// Original components with styling

// Card wrapper with rounded borders (5px)
const CardWrapper = styled(Card)(({ height }) => ({
  height,
  margin: '10px',
  flex: 1,
  fontFamily: 'MAN EUROPE_Bold',
  border: '1px solid #ccc', // Added border
  borderRadius: '5px',
  boxShadow:'none',
}));

// Custom Tooltip with white background and black text
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    color: 'black',
  },
  '& .MuiTooltip-arrow': {
    color: 'white',
  },
});

// Header component extended to accept an "action" prop for the info icon
const Header = styled(CardHeader)(
  ({ height, titleFontSize, titleMarginBottom, textAlign }) => ({
    color: 'black',
    padding: '4px 0px',
    fontSize: '10',
    height,
    backgroundColor: 'transparent',
    fontFamily: 'MAN EUROPE_Bold',
    borderRadius: '5px 5px 5px 5px',
    ...(textAlign && { textAlign }),
    '& .MuiCardHeader-title': {
      fontSize: '18px',
      titleFontSize,
      marginBottom: titleMarginBottom,
      marginLeft: 10,
      fontFamily: 'MAN EUROPE_Bold',
    },
  })
);

// Content component with matching rounded borders on the bottom
const Content = styled(CardContent)(({ fontSize, marginBottom }) => ({
  fontSize: 'larger',
  fontWeight: 'bold',
  textAlign: 'end',
  marginBottom,
  backgroundColor: 'transparent',
  color: 'black',
  fontFamily: 'MAN EUROPE_Bold',
  borderRadius: '5px 5px 5px 5px',
}));

// Styled component for the numeric value
const ValueText = styled('span')({
  fontSize: '1.2rem', // Adjust size as needed
  fontWeight: 'bold',
  color: 'black',
  fontFamily: 'MAN EUROPE_Bold',
});

// Styled component for the unit text (matching header style)
const UnitText = styled('span')({
  fontSize: '0.750rem',
  color: 'black',
  fontFamily: 'MAN EUROPE_regular',
  marginLeft: '0.25rem',
});

const Summary = () => {
  const { graphData } = useGraphContext();
  const {
    maps,
    mileage_vin,
    all_summary_test,
    charging_cycle,
    all_summary_test_table,
  } = graphData;

  const [accordionExpanded, setAccordionExpanded] = React.useState(false);
  const handleAccordionChange = (event, isExpanded) =>
    setAccordionExpanded(isExpanded);

  // Download handler for the export button
  const handleExportClick = (e, vehicleData) => {
    e.stopPropagation();

    if (!vehicleData || vehicleData.length === 0) {
      alert('No data available to export.');
      return;
    }

    const columns = Object.keys(vehicleData[0]);
    const headers = columns.join(',') + '\n';
    const rows = vehicleData
      .map((row) => columns.map((col) => `${row[col]}`).join(','))
      .join('\n');

    const csvContent = headers + rows;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'vehicle_long_list.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Define a common image style for the info icon image
  const infoImgStyle = { width: '16px', height: '16px' };

  return (
    <>
      {/* Main Container */}
      <div
        style={{
          display: 'flex',
          backgroundColor: 'white',
          fontFamily: 'MAN EUROPE_Bold',
          borderRadius: '5px',
        }}
      >
        {/* Left Section */}
        <div
          style={{
            width: '60%',
            backgroundColor: 'white',
            margin: '10px',
            // border: '1px solid #ccc', // Added border
            // borderRadius: '5px',
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '5px' }}>
            <div style={{ flex: 2 }}>
              <CardWrapper height="161px">
                <Header
                  title="Total mileage"
                  height="101px"
                  titleFontSize={17}
                  titleMarginBottom={60}
                  action={
                    <CustomTooltip
                      title="Total mileage (sum) of selected vehicle(s) since start of data acquisition"
                      arrow
                    >
                      <IconButton
                        size="small"
                        style={{
                          marginRight: '5px',
                          transform: 'translateX(-5px)',
                        }}
                      >
                        <img src={ifav} alt="info icon" style={infoImgStyle} />
                      </IconButton>
                    </CustomTooltip>
                  }
                />
                <Content fontSize="25px" marginBottom="0px">
                  <ValueText>{all_summary_test[0].total_mileage}</ValueText>
                  <UnitText> km</UnitText>
                </Content>
              </CardWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <CardWrapper height="161px">
                <Header
                  title="Ø Daily driving time"
                  height="101px"
                  titleFontSize={17}
                  titleMarginBottom={60}
                  action={
                    <CustomTooltip
                      title="Average daily total driving time of selected vehicle(s) during the past 7 calendar days"
                      arrow
                    >
                      <IconButton
                        size="small"
                        style={{
                          marginRight: '5px',
                          transform: 'translateX(-5px)',
                        }}
                      >
                        <img src={ifav} alt="info icon" style={infoImgStyle} />
                      </IconButton>
                    </CustomTooltip>
                  }
                  style={{ backgroundColor: 'transparent' }}
                />
                <Content
                  fontSize="25px"
                  marginBottom="0px"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <ValueText>{all_summary_test[0].avg_driving_time}</ValueText>
                  <UnitText> h</UnitText>
                </Content>
              </CardWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <CardWrapper height="161px">
                <Header
                  title="Ø Daily standing time"
                  height="101px"
                  titleFontSize={17}
                  titleMarginBottom={60}
                  action={
                    <CustomTooltip
                      title="Average daily total standing time of selected vehicle(s) during the past 7 calendar days"
                      arrow
                    >
                      <IconButton
                        size="small"
                        style={{
                          marginRight: '5px',
                          transform: 'translateX(-5px)',
                        }}
                      >
                        <img src={ifav} alt="info icon" style={infoImgStyle} />
                      </IconButton>
                    </CustomTooltip>
                  }
                  style={{ backgroundColor: 'transparent' }}
                />
                <Content
                  fontSize="25px"
                  marginBottom="0px"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <ValueText>{all_summary_test[0].avg_standing_time}</ValueText>
                  <UnitText> h</UnitText>
                </Content>
              </CardWrapper>
            </div>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flex: 1 }}>
              <CardWrapper height="161px">
                <Header
                  title="Ø Energy consumption"
                  height="101px"
                  titleFontSize={17}
                  titleMarginBottom={60}
                  action={
                    <CustomTooltip
                      title="Average energy consumption of selected vehicle(s) during the past 7 calendar days"
                      arrow
                    >
                      <IconButton
                        size="small"
                        style={{
                          marginRight: '5px',
                          transform: 'translateX(-5px)',
                        }}
                      >
                        <img src={ifav} alt="info icon" style={infoImgStyle} />
                      </IconButton>
                    </CustomTooltip>
                  }
                />
                <Content fontSize="25px" marginBottom="0px">
                  <ValueText>
                    {all_summary_test[0].avg_energy_consumption}
                  </ValueText>
                  <UnitText> kWh/100km</UnitText>
                </Content>
              </CardWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <CardWrapper height="161px">
                <Header
                  title="Ø Charging power"
                  height="101px"
                  titleFontSize={17}
                  titleMarginBottom={60}
                  action={
                    <CustomTooltip
                      title="Average charging power of selected vehicle(s) during the past 7 calendar days"
                      arrow
                    >
                      <IconButton
                        size="small"
                        style={{
                          marginRight: '5px',
                          transform: 'translateX(-5px)',
                        }}
                      >
                        <img src={ifav} alt="info icon" style={infoImgStyle} />
                      </IconButton>
                    </CustomTooltip>
                  }
                />
                <Content fontSize="25px" marginBottom="0px">
                  <ValueText>
                    {all_summary_test[0].avg_charging_power}
                  </ValueText>
                  <UnitText> kW</UnitText>
                </Content>
              </CardWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', height: '180px' }}>
                <CardWrapper height="80px">
                  <Header
                    title="Ø Daily mileage"
                    height="25px"
                    titleFontSize={14}
                    titleMarginBottom={0}
                    action={
                      <CustomTooltip
                        title="Average daily mileage of selected vehicle(s) during the past 7 calendar days"
                        arrow
                      >
                        <IconButton
                          size="small"
                          style={{
                            marginRight: '5px',
                            transform: 'translateX(-5px)',
                          }}
                        >
                          <img src={ifav} alt="info icon" style={infoImgStyle} />
                        </IconButton>
                      </CustomTooltip>
                    }
                  />
                  <Content fontSize="12px" marginBottom="10px">
                    <ValueText>
                      {all_summary_test[0].avg_daily_mileage}
                    </ValueText>
                    <UnitText> km</UnitText>
                  </Content>
                </CardWrapper>
                <CardWrapper height="80px">
                  <Header
                    title="Max daily mileage"
                    height="25px"
                    titleFontSize={14}
                    titleMarginBottom={0}
                    action={
                      <CustomTooltip
                        title="Maximum individual daily mileage value of selected vehicle(s) during the past 7 calendar days"
                        arrow
                      >
                        <IconButton
                          size="small"
                          style={{
                            marginRight: '5px',
                            transform: 'translateX(-5px)',
                          }}
                        >
                          <img src={ifav} alt="info icon" style={infoImgStyle} />
                        </IconButton>
                      </CustomTooltip>
                    }
                  />
                  <Content fontSize="12px" marginBottom="10px">
                    <ValueText>
                      {all_summary_test[0].max_daily_mileage}
                    </ValueText>
                    <UnitText> km</UnitText>
                  </Content>
                </CardWrapper>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <CardWrapper height="161px">
                <Header
                  title="Ø Speed"
                  height="101px"
                  titleFontSize={17}
                  titleMarginBottom={60}
                  action={
                    <CustomTooltip
                      title="Average speed of selected vehicle(s) during the past 7 calendar days"
                      arrow
                    >
                      <IconButton
                        size="small"
                        style={{
                          marginRight: '5px',
                          transform: 'translateX(-5px)',
                        }}
                      >
                        <img src={ifav} alt="info icon" style={infoImgStyle} />
                      </IconButton>
                    </CustomTooltip>
                  }
                />
                <Content fontSize="25px" marginBottom="0px">
                  <ValueText>{all_summary_test[0].avg_speed}</ValueText>
                  <UnitText> km/h</UnitText>
                </Content>
              </CardWrapper>
            </div>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
            <div style={{ flex: 1 }}>
              <CardWrapper>
                <Header
                  title="Total number of charging cycles"
                  titleFontSize={14}
                  textAlign="start"
                  action={
                    <CustomTooltip
                      title="The total number of charging cycles since start of data acquisition is displayed for the top 5 vehicles. Additionally the average value on total number of charging cycles over all selected vehicles is displayed."
                      arrow
                    >
                      <IconButton
                        size="small"
                        style={{
                          marginRight: '5px',
                          transform: 'translateX(-5px)',
                        }}
                      >
                        <img src={ifav} alt="info icon" style={infoImgStyle} />
                      </IconButton>
                    </CustomTooltip>
                  }
                />
                <Content>
                  <Charging_cycle value={charging_cycle} />
                </Content>
              </CardWrapper>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div
          style={{
            width: '40%',
            marginTop: '15px',
            marginRight: '10px',
            borderRadius: '5px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
            padding: '10px',
          }}
        >
          {/* Vehicle Total Mileage Section */}
          <div
            style={{
              flex: 1,
              padding: '10px',
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',
              paddingBottom: '15px',
              border: '1px solid #ccc', // Added border
              borderRadius: '5px',
              // boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12', // Added box shadow
            }}
          >
            <h6
              style={{
                fontFamily: 'MAN EUROPE_Bold',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Vehicle total mileage at a glance
              <CustomTooltip
                title="Total mileage of each selected vehicle since start of data acquisition"
                arrow
              >
                <IconButton
                  size="small"
                  style={{
                    marginLeft: '5px',
                    transform: 'translateX(-5px)',
                  }}
                >
                  <img src={ifav} alt="info icon" style={infoImgStyle} />
                </IconButton>
              </CustomTooltip>
            </h6>
            <Mileage value={mileage_vin} plotColor={plotColor} />
          </div>

          {/* Fleet Distribution Section */}
          <div
            style={{
              flex: 1,
              padding: '10px',
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',
              // marginTop: '21px',
              border: '1px solid #ccc', // Added border
              borderRadius: '5px',
              // boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12', // Added box shadow

            }}
          >
            <h6
              style={{
                fontFamily: 'MAN EUROPE_Bold',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Distribution of fleet across countries
              <CustomTooltip
                title="Visualisation of number of vehicles per sales country"
                arrow
              >
                <IconButton
                  size="small"
                  style={{
                    marginLeft: '5px',
                    transform: 'translateX(-5px)',
                  }}
                >
                  <img src={ifav} alt="info icon" style={infoImgStyle} />
                </IconButton>
              </CustomTooltip>
            </h6>
            <Map maps={maps} plotColor={plotColor} />
          </div>
        </div>
      </div>

      {/* Accordion Section for Vehicle Long List */}
      <div
        style={{
          margin: '60px 10px 50px 10px',
          position: 'relative',
          backgroundColor: '#d3d3d3',
          borderRadius: '5px',
        }}
      >
        <Accordion
          expanded={accordionExpanded}
          onChange={handleAccordionChange}
          style={{ backgroundColor: 'rgb(248, 248, 248)', borderRadius: '5px' }}
        >
          <AccordionSummary
            aria-controls="vehicle-list-content"
            id="vehicle-list-header"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                style={{ marginLeft: '1.5rem', fontFamily: 'MAN EUROPE_Bold' }}
              >
                Vehicle long list
              </Typography>
              <div style={{ marginLeft: '1rem' }}>
                {accordionExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <DynamicTable data={all_summary_test_table} />
          </AccordionDetails>
        </Accordion>
        <IconButton
          onClick={(e) => handleExportClick(e, all_summary_test_table)}
          size="large"
          onMouseDown={(e) => e.stopPropagation()}
          style={{
            position: 'absolute',
            top: '5px',
            right: '25px',
            color: 'black',
          }}
        >
          <DownloadIcon />
        </IconButton>
      </div>
    </>
  );
};

export default Summary;
