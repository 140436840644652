import {
  Charging_mode,
  Charging_power,
  Battery_temp_fast,
  Charging_power_fast,
  Charging_power_normal,
  Battery_temp_normal
}
from './charging_analysis_kpi'
import { useGraphContext } from '../GraphContext';
import { Card } from 'react-bootstrap';
import ChargingCategorization from './Charging_description';


const ChargingAnalysis = () => {
  const { graphData } = useGraphContext();

  const {
      system_charging_power_kw,
      battery_temp_during_fast_charging,
      charging_power_during_fast_charging,
      charging_power_during_normal_charging,
      battery_temp_during_normal_charging,
      vin_short,
      charging_power_normal_daily_avg_statement,
      batterytemp_charging_fast_daily_avg_statement,
      batterytemp_charging_normal_avg_statement,
      charging_cycle
  }=graphData;



return (
  <div style={{backgroundColor:'white'}}>
    {/* <ChargingCategorization /> */}
  <div className="card-info_charging">
  <Card className='firstcard_charging'>
  <Charging_mode value={charging_cycle}/>
  </Card>
  <Card className='firstcard_charging'>
  <Charging_power value={{system_charging_power_kw,vin_short,charging_power_normal_daily_avg_statement}}/>
  </Card>
  <Card className='firstcard_charging'>
  <Battery_temp_fast value={{battery_temp_during_fast_charging,vin_short,batterytemp_charging_fast_daily_avg_statement}}/>
  </Card>
  <Card className='firstcard_charging'>
  <Battery_temp_normal value={{battery_temp_during_normal_charging,vin_short,batterytemp_charging_normal_avg_statement}}/>
  </Card>
  <Card className='firstcard_charging'>
  <Charging_power_fast value={charging_power_during_fast_charging}/>
  </Card>
  <Card className='firstcard_charging'>
  <Charging_power_normal value={charging_power_during_normal_charging}/>
  </Card>

  </div>
  
  </div>

  
)
}

export default ChargingAnalysis;
