import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";

const Charging_cycle = ({ value }) => {
  const [Charging_cycle, set_Charging_cycle] = useState(null);

  useEffect(() => {
    try {
        set_Charging_cycle(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '460px' }}>
    <Plot 
        data={Charging_cycle?.data} 
        layout={{
            ...Charging_cycle?.layout,
            autosize: true,
            responsive: true,
            constraintowindow: true,
            margin: { t: 10, r: 10, l: 10, b: 10 }
        }}
        style={{ width: '90%', height: '90%' }}
        useResizeHandler={true}
    />
</div>

  
  );
};


Charging_cycle.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Charging_cycle;
