import React, { useState } from 'react';
import Select from 'react-select';
import { ManText, ManSelect, ManSelectItem,ManInputText} from "@craft-design-system/core-components-react";
import Yearly_mileage_filter from './Yearly_mileage_filter';
 // Adjust import based on your actual file structure
 import filters from '../assets/images/filters.svg';

const FilterSidebar = ({ dropdownData, selectedValues, handleSelectChange, customStyles,onvaluesChange}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='sidebar1' style={{ marginTop: '10px' }}>
      <div className="dropdown-section">
        {dropdownData &&
          Object.entries(dropdownData).slice(0, 7).map(([columnName, values]) => {
            // Map dropdown options
            const options = values.map(value => ({ label: value, value }));
            return (
              <div className="sidebar-filters" key={columnName}>
                <ManText className="filter-text" variant={"heading-s"} as={"span"}>
                  {columnName}
                </ManText>

                <Select
                  isMulti
                  name={columnName}
                  options={options}
                  // Safely map selected values to display in the dropdown
                  value={selectedValues && selectedValues[columnName] ?
                    selectedValues[columnName].map(val => ({ label: val, value: val })) :
                    []
                  }
                  styles={customStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) => handleSelectChange(event, columnName)}
                />
              </div>
            );
          })
        }

        {/* Collapsible Section for Additional Filters */}
        {isOpen && dropdownData && 
  Object.entries(dropdownData).slice(7).map(([columnName, values]) => {
    const options = values.map(value => ({ label: value, value }));
    
    return (
      <div className="sidebar-filters" key={columnName}>
        <ManText className="filter-text" variant={"heading-s"} as={"span"}>
          {columnName}
        </ManText>

        <Select
          isMulti
          name={columnName}
          options={options}
          value={selectedValues && selectedValues[columnName] ?
            selectedValues[columnName].map(val => ({ label: val, value: val })) :
            []
          }
          styles={customStyles}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(event) => handleSelectChange(event, columnName)}
        />
      </div>
    );
  })
}

{/* Add the Yearly_mileage_filter component conditionally */}
{isOpen && 
  <Yearly_mileage_filter onValuesChange={onvaluesChange} />
}

        
        

        <ManText variant={"heading-s"} as={"span"}>
          <button className='filters_button' onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'Less filters' : 'More filters'}
            <img src={filters} alt="button image" className='filter_image' />
          </button>
        </ManText>
      </div>
    </div>
  );
};

export default FilterSidebar;
