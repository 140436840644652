import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';
import { Card } from 'react-bootstrap';
import PopoverComponent from '../popover';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const DOD_config = ({ value }) => {
  const [dod_config, set_software_stage] = useState(null);

//   //console.log('test',value)


//   //console.log('test',dod_config)






  useEffect(() => {
    try {
        set_software_stage(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
<Card className='card_battery' >      
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      DOD configuration
      </ManText>
      <PopoverComponent id= 'median_consumption' content='This KPI states the installed depth of discharge (DoD) configuration on the vehicle. The value is taken from the vehicle configuration.

If more than one vehicle is selected the distinct number of vehicles per DoD configuration is displayed.' />



      {dod_config && <Plot 
          data={dod_config.data} 
          layout={{
              ...dod_config.layout,
              autosize: true,
              responsive: true,
              margin: { t: 10, r: 10, l: 10, b: 10 }
          }}
          style={{ width: '100%', height: '90%' }}
          useResizeHandler={true}
      />} 
      </Card>
  );
};

DOD_config.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DOD_config;