const hostname = window.location.hostname;
const isDevelopment = hostname === "localhost" || hostname.includes("bev-dev.simbaanalytics-dev.rio.cloud");
const isProduction = hostname.includes("bev-prod.simbaanalytics-dev.rio.cloud");

const authConfig = {
    cognitoDomain: isDevelopment
        ? "bevdash.auth.eu-west-1.amazoncognito.com" // Replace with actual Dev Cognito Domain
        : isProduction
        ? "eu-west-1qlpddfde6.auth.eu-west-1.amazoncognito.com" // Replace with actual Prod Cognito Domain
        : "default-cognito-domain.auth.eu-west-1.amazoncognito.com", // Fallback Cognito Domain

    clientId: isDevelopment
        ? "188p39j745l2qnou9fhklduqr6" // Replace with actual Dev Client ID
        : isProduction
        ? "36cmj0jsa01l5fntbr1rn2v9pv" // Replace with actual Prod Client ID
        : "DEFAULT_CLIENT_ID", // Fallback Client ID

    redirectUri: isDevelopment
        ? "https://bev-dev.simbaanalytics-dev.rio.cloud/"
        : isProduction
        ? "https://bev-prod.simbaanalytics-dev.rio.cloud/"
        : "", // Fallback URL

    responseType: "code",
    scope: "openid phone email",
};

export default authConfig;
