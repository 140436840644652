import { useEffect, useState } from "react";
import { useResetContext} from '../../components/ResetContext';

// Custom hook to fetch dropdown data
const useDynamicFiltering = () => {
  const [dropdownInfo, setDropdownInfo] = useState(null);
  const {resetdata, updateresetdata} = useResetContext();



  const dynamicFiltering = async ({ selected_value }) => {
    try {
      let baseURL;
      if (window.location.hostname === "simbaanalytics-dev.rio.cloud") {
        baseURL = "https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws";
      } else if (window.location.hostname === "bev-dev.simbaanalytics-dev.rio.cloud") {
        baseURL = "https://spv3toxlwj.execute-api.eu-west-1.amazonaws.com/test_cognito";
      } else if (window.location.hostname === "bev-prod.simbaanalytics-dev.rio.cloud") {
        baseURL = "https://dnz0zln7l2.execute-api.eu-west-1.amazonaws.com/prod";
      } else {
        baseURL = "http://localhost:8080";
      }
  
      // Retrieve the ID token from localStorage
      const token = localStorage.getItem("idToken");
  
      if (!token) {
        console.error("No ID token found! User might not be authenticated.");
        return;
      }
  
      // Prepare request headers with Authorization
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach ID token
      };
  
      // Construct request body
      const valuesToSend = { ...selected_value, resetdata };
  
      // Make the API request with authentication
      const response = await fetch(`${baseURL}/dynamic-filtering`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(valuesToSend),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  // Use useEffect to call the dynamicFiltering function when the component mounts and whenever selected_value changes
  // useEffect(() => {
  //   if (selected_value!=null){
  //   dynamicFiltering();
  //   }
  // }, [selected_value]);

  return {dynamicFiltering,dropdownInfo};
};

// Export the custom hook for use in other components
export default useDynamicFiltering;
