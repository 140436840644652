import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import management_summary from '../assets/images/New_features_images/management_summary.png';
import AI_Daily_mileage from '../assets/images/New_features_images/AI_Daily_mileage.png';
import ChargingCategorization from  '../components/Charging_description'
import charging_logic from '../assets/images/New_features_images/charging_logic.png';
const NewFeaturesModal = () => {
  const [showModal, setShowModal] = useState(false);


  // Use localStorage to show the modal only once
  useEffect(() => {
    const isFirstVisit2 = localStorage.getItem('Release 1.3');
    if (!isFirstVisit2) {
      setShowModal(true);
      localStorage.setItem('Release 1.3', 'true');
    }
  }, []);

  const handleClose = () => setShowModal(false);

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Body>
        <h5>09-05 Release 1.3 | 04/03/2025 | Enhancements & Bug Fixes</h5>
        
        <h6>Management Summary</h6>
        <p>
          A new page has been added that summarizes the key metrics and provides a direct overview of the usage of the selected vehicles.
          You can view the details on the familiar pages “Ride Analysis,” “Battery,” and “Charging.”
        </p>
        <img src={management_summary} alt="Management Summary" style={{ width: '100%' }} />
        
        <h6>AI | Automotive Intelligence</h6>
        <p>
          You will receive static trend assessments for many metrics, allowing you to better evaluate whether values align with other periods or if there are changes.
        </p>
        <p>
          This is a beta version and right now focusing on trend assessments on KPIs with daily/monthly/yearly aggregations.
          The latest time period (e.g. yesterday) is compared to a longer time period (e.g. last month).
        </p>
        <img src={AI_Daily_mileage} alt="AI Daily Mileage" style={{ width: '100%' }} />
        
        <h6>Additional Filter Options</h6>
        <p>
          The filter options “Maturity” and “Sales country” have been added. With “Maturity,” you can distinguish between prototypes and series vehicles,
          and for trucks, whether they are ExEF vehicles.
        </p>
      

        <h6>Bug Fixes</h6>
        <p>Minor changes due to bugs.</p>
        
        <h6>User Feedback Survey</h6>
        <p>
          Based on user feedback, the application is continuously optimized. We therefore ask you to participate in this survey: 
          <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=qj3GzEvYvUqY_U7rg53ic_r0re8eomxHgX8-yXOa0gBUMDlCR1E1SlcxMkhWOFBVR0VRUk9GMDZKTy4u" target="_blank" rel="noopener noreferrer"><strong>BEV Analyzer: User Feedback Survey</strong></a>        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewFeaturesModal;
