import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import ifav from './ifav.png';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const Depth_of_discharge = ({ value }) => {
  const [depth_of_discharge, set_depth_of_discharge] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

   //console.log('value',value)
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The KPI displays the daily depth of discharge in percent. 100% equals the battery capacity of the vehicle. The value can exceed 100% (over course of the day battery can be discharged and charged multiple times). 

      If more than one vehicle is selected average values are displayed.
      </Popover.Body>
    </Popover>
  );

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };

  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      //console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Depth_discharge'}).then(data => {
        set_depth_of_discharge(JSON.parse(data['jsonData']));
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);

  useEffect(() => {
    //console.log('testing23456', depth_of_discharge);
  }, [depth_of_discharge]);

  useEffect(() => {
    try {
      set_depth_of_discharge(JSON.parse(value.depth_of_discharge));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  //console.log('depth_of_discharge',depth_of_discharge)

  

  return (
    <div >
      <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
        Depth of discharge
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>

      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      {/* <ManRadioButtonGroup 
        name="Depth_of_discharge"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Depth_of_discharge" label="Daily" value="Daily" checked />
        <ManRadioButton name="Depth_of_discharge" label="Monthly" value="Monthly" />
        <ManRadioButton name="Depth_of_discharge" label="Yearly" value="Yearly" />
      </ManRadioButtonGroup> */}
      </div>
      {isLoading ? (
    <Shimmer_graph />
) : (
    depth_of_discharge && depth_of_discharge.data ? (
        <Plot 
            data={depth_of_discharge.data} 
            layout={{
                ...depth_of_discharge.layout,
                autosize: true,
                responsive: true,
                margin: { t: 10, r: 10, l: 10, b: 10 }
            }}
            style={{ width: '100%', height: '85%' }}
            useResizeHandler={true}
        />
    ) : (
      <ManText className="title" variant="body-l" as="span">
      No Data Available
    </ManText>
    )
)} 
      </div>
  );
};

Depth_of_discharge.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Depth_of_discharge;