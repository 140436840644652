import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import ifav from './ifav.png';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { CustomTextDisplay } from '../AI';
const Battery_temp_normal = ({ value }) => {
  const [Battery_temp_normal, set_Battery_temp_normal] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showMonthlyText, setShowMonthlyText] = useState(value.battery_temp_during_normal_charging[1]['battery_normal_charging']);


  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The battery temperature (system) during normal charging (below 40 kW/pack) is displayed as follows:

Temperature distribution during normal charging cycle(s) per selected time interval - If more than one vehicle is selected average values are displayed.
      </Popover.Body>
    </Popover>
  ); 

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };

  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      //console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Battery temp normal'}).then(data => {
        set_Battery_temp_normal(JSON.parse(data['jsonData'][0]));
        setShowMonthlyText(data['jsonData'][1]['battery_normal_charging'])
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);
  //console.log('Battery_temp_normal',Battery_temp_normal,value)

  useEffect(() => {
    //console.log('testing23456', Battery_temp_normal);
  }, [Battery_temp_normal]);

  useEffect(() => {
    try {
      set_Battery_temp_normal(JSON.parse(value.battery_temp_during_normal_charging[0]));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);


  

  return (
    <div >
      <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Battery temperature (system) during normal charging
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>

      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      <ManRadioButtonGroup 
        name="Battery Temperature Normal Charge"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Battery Temperature Normal Charge" label="Daily" value="Daily" checked />
        <ManRadioButton name="Battery Temperature Normal Charge" label="Monthly" value="Monthly" />
        <ManRadioButton name="Battery Temperature Normal Charge" label="Yearly" value="Yearly" />
      </ManRadioButtonGroup>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>

      {isLoading ? <Shimmer_graph /> : Battery_temp_normal && 
      <>
      <Plot 
        data={Battery_temp_normal.data} 
        layout={{
            ...Battery_temp_normal.layout,
            // autosize: true,
            // responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 170 }
        }}
        // style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
    />
                  <CustomTextDisplay
              title="AI | Automotive Intelligence"
              value={showMonthlyText}
              isValueStyled
            /> 
    </>
    
    }
 
            </div>

      </div>
  );
};

Battery_temp_normal.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Battery_temp_normal;