import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
} from "@mui/material";

const DynamicTable = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");

  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const columns = Object.keys(data[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0);
  };

  const filteredData = data.filter((row) =>
    columns.some((col) => row[col].toString().toLowerCase().includes(searchQuery))
  );

  return (
    <Paper>
      <TextField
        style={{ padding: "10px" }}
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={handleSearchChange}
      />
      <TableContainer style={{ maxWidth: "100%", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col}
                  style={{
                    fontFamily: "MAN EUROPE_Bold",
                    fontWeight: "bold",
                    backgroundColor: "#fff",
                    position: col === "vin_short" ? "sticky" : "static",
                    left: col === "vin_short" ? 0 : "auto",
                    zIndex: col === "vin_short" ? 2 : "auto",
                    boxShadow: col === "vin_short" ? "2px 0px 5px rgba(0,0,0,0.1)" : "none",
                  }}
                >
                  {col.toUpperCase()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                {columns.map((col) => (
                  <TableCell
                    key={col}
                    style={{
                      fontFamily: "MAN EUROPE_Bold",
                      position: col === "vin_short" ? "sticky" : "static",
                      left: col === "vin_short" ? 0 : "auto",
                      zIndex: col === "vin_short" ? 1 : "auto",
                      backgroundColor: "#fff",
                    }}
                  >
                    {row[col]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DynamicTable;
