import React from 'react';
import { useGraphContext } from '../GraphContext';
import TabComponent from './tabs_graphs';
import Shimmer_graph from './shimmer_graph';
import  Shimmer_graph_man from './MAN_info_graphs'
import Donut from './Donut'
import Selected_filters from './Selected_filters';

const Graphs = () => {


  const { graphData } = useGraphContext();

  console.log('graphData',graphData)

  if (!graphData) {
    return <Shimmer_graph_man />;
  }
  console.log('Graphs',graphData);
  if (graphData.message === 'Wrong filets') {
    return (
      <div className='Graphs' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>Incorrect filter options selected!!</div>
      </div>
    );
  } else if (graphData.message === 'No Data') {
    return (
      <div className='Graphs' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>No Data Available</div>
      </div>
    );
  } else if (graphData.message === 'Unable to update graph data: Please try again after some time.') {
    return (
      <div className='Graphs' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>  Statistics cannot be generated with your current selection. The issue has been reported to the development team. Please try again later
</div>
      </div>
    );

  }


  const {donut_chart}=graphData;



  

  return (
    <div className='Graphs'>
      {graphData ?
      <>
      <div>
      <Donut value={{ donut_chart}} />
      <Selected_filters  />
      <TabComponent initial_tab='Summary'/>
      </div>
      </> 

      : <Shimmer_graph_man />}
    </div>
  );
}

export default Graphs;