import { useEffect, useState } from "react";
import { couldStartTrivia } from "typescript";

// Custom hook to fetch dropdown data
const useDropdownData = () => {
  // State to store dropdown information
  const [dropdowndata, setDropdownInfo] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);


  // console.log('in testselectedValues',selected_value);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);



  const getToken = () => localStorage.getItem("idToken");

  const fetchData = async () => {
  setIsRefreshing(true);

  try {
    let baseURL;
    if (window.location.hostname === "simbaanalytics-dev.rio.cloud") {
      baseURL = "https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws";
    } else if (window.location.hostname === "bev-dev.simbaanalytics-dev.rio.cloud") {
      baseURL = "https://spv3toxlwj.execute-api.eu-west-1.amazonaws.com/test_cognito";
    } else if (window.location.hostname === "bev-prod.simbaanalytics-dev.rio.cloud") {
      baseURL = "https://dnz0zln7l2.execute-api.eu-west-1.amazonaws.com/prod";
    } else {
      baseURL = "http://localhost:8080";
    }

    // Get the token from localStorage
    const token = getToken();

    // Set up headers with Bearer token
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await fetch(`${baseURL}/dropdown`, {
      method: "GET",
      headers: headers,
    });

    // Check if the response is not okay and throw an error if needed
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse the JSON response
    const jsonData = await response.json();

    // console.log('jsondata_initialloading', jsonData);

    // Set the dropdown information in the state
    setDropdownInfo(jsonData);
  } catch (error) {
    // Log any errors that occur during the fetching process
    console.error("Error fetching data:", error);
  }

  setIsRefreshing(false);
};




  // console.log('dropdownInfo',dropdownInfo)

  // Return the fetched dropdown information
  return {dropdowndata, fetchData,isRefreshing};
};

// Export the custom hook for use in other components
export default useDropdownData;
