import { useEffect, useState } from "react";

const useGraphRadio = () => {

  const [radio_data,set_radio_data] = useState(null);

  const RadioQuery = async ({ vins_short, radio_value, columnname, category }) => {
    console.log("vin_shortecec", vins_short, radio_value, columnname, category);
  
    try {
      let baseURL;
      if (window.location.hostname === "simbaanalytics-dev.rio.cloud") {
        baseURL = "https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws";
      } else if (window.location.hostname === "bev-dev.simbaanalytics-dev.rio.cloud") {
        baseURL = "https://spv3toxlwj.execute-api.eu-west-1.amazonaws.com/test_cognito";
      } else if (window.location.hostname === "bev-prod.simbaanalytics-dev.rio.cloud") {
        baseURL = "https://dnz0zln7l2.execute-api.eu-west-1.amazonaws.com/prod";
      } else {
        baseURL = "http://localhost:8080";
      }
  
      // Retrieve the ID token from localStorage
      const token = localStorage.getItem("idToken");
  
      if (!token) {
        console.error("No ID token found! User might not be authenticated.");
        return;
      }
  
      // Prepare request headers with Authorization
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach ID token
      };
  
      // Make API request with authentication
      const response = await fetch(`${baseURL}/radio-dropdown-graph`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ vins_short, radio_value, columnname, category }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const jsonData = await response.json();
      return { jsonData };
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  // //console.log('graph_dataefef',radio_data);

  return RadioQuery;
};

export default useGraphRadio;
