import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
// import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import { Card } from 'react-bootstrap';
// import PopoverComponent from '../popover';

const formatNumber = (number) => {
  if (isNaN(number)) return ''; // Return as is if not a number
  return number.toLocaleString('de-DE');      // 'de-DE' for German locale, 'en-IN' for Indian English locale
};

const TotalMileageCard = ({ value }) => {
  const [total_mileage, set_total_mileage] = useState(null);

  console.log('packs',value)








  useEffect(() => {
    try {
        set_total_mileage(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <Card className='card_ride' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Total mileage     
      </ManText>
      <PopoverComponent id= 'median_consumption' content='This KPI displays the driven distance of the vehicle within one calendar day. 
If more than one vehicle is selected average values are displayed.' />
      


      {total_mileage && total_mileage.data ? (
        <Plot 
        data={total_mileage.data} 
        layout={{
            ...total_mileage.layout,
            autosize: true,
            responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 10 }
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
    />
      ) : (
        <div>
        <ManText className="card-title-text" variant="component-l" as="span" size="l">{value} km</ManText>
        </div>
      )}
      </Card>
  );
};

TotalMileageCard.propTypes = {
  value: PropTypes.number.isRequired,
};

export default TotalMileageCard;