import { useGraphContext } from "../../GraphContext";
import pako from 'pako';
import { useResetContext} from '../../components/ResetContext';

const useHandleQuery = () => {
  const { graphData,updateGraphData } = useGraphContext();
  const {resetdata, updateresetdata} = useResetContext();

  const handleQuery = async ({ selectedValues, username }) => {
    try {
      let baseURL;
      if (window.location.hostname === "simbaanalytics-dev.rio.cloud") {
        baseURL = "https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws";
      } else if (window.location.hostname === "bev-dev.simbaanalytics-dev.rio.cloud") {
        baseURL = "https://spv3toxlwj.execute-api.eu-west-1.amazonaws.com/test_cognito";
      } else if (window.location.hostname === "bev-prod.simbaanalytics-dev.rio.cloud") {
        baseURL = "https://dnz0zln7l2.execute-api.eu-west-1.amazonaws.com/prod";
      } else {
        baseURL = "http://localhost:8080";
      }
  
      // Retrieve the ID token from localStorage
      const token = localStorage.getItem("idToken");
  
      if (!token) {
        console.error("No ID token found! User might not be authenticated.");
        return;
      }
  
      // Prepare request headers with Authorization
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach ID token
      };
  
      // Construct request body
      const valuesToSend = { ...selectedValues, resetdata, username };
      console.log("valuesToSend", valuesToSend);
  
      // Make API request with authentication
      const response = await fetch(`${baseURL}/selected-option`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(valuesToSend),
      });
  
      if (!response.ok) {
        updateGraphData({ message: "Unable to update graph data: Please try again after some time." });
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      console.log("response", response);
  
      // Check the Content-Encoding header of the response
      const contentEncoding = response.headers.get("Content-Encoding");
  
      let result;
      if (contentEncoding === "gzip") {
        // If the response is gzip-compressed, decompress it
        const compressedData = await response.arrayBuffer();
        const decompressedData = pako.ungzip(new Uint8Array(compressedData), { to: "string" });
        result = JSON.parse(decompressedData);
      } else {
        // If the response isn't gzip-compressed, parse it as JSON directly
        result = await response.json();
      }
  
      updateGraphData(result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  

  // //console.log('graph_data',graphData);

  return handleQuery;
};

export default useHandleQuery;
