import React from 'react';
import { ManRadioButtonGroup, ManRadioButton, ManText } from '@craft-design-system/core-components-react';

const CustomTextDisplay = ({ value }) => {
  return (
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
      {/* Title Section */}
      <div
      style={{ 
        position: 'relative',
        top: '18px',
        background: 'white',
        width: '270px',
        left: '3px'
      }}
      >
      <ManText
        className="daily-mileage-text"
        variant="component-l"
        as="span"
        size="xl"
        style={{
          textAlign: 'left',
          display: 'block',
          padding: '10px',
        }}
      >
        AI | Automotive Intelligence <span style={{color: '#CBD3DC'}}>(BETA)</span>      </ManText>
      </div>

      {/* Value Section */}
      <div>
      <ManText
  className="daily-mileage-text"
  variant="body-m"
  as="span"
  size="xl"
  style={{
    border: '2px solid black',
    borderRadius: '8px',
    height: '72px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: '10px',
  }}
>
  <span dangerouslySetInnerHTML={{ __html: value }} />
</ManText>

</div>

    </div>
  );
};

export default CustomTextDisplay;
