import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import ifav from './ifav.png';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { CustomTextDisplay } from '../AI';
import { useResetContext} from '../ResetContext';




const Charging_power = ({ value }) => {
  const [charging_power, set_charging_power] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showMonthlyText, setShowMonthlyText] = useState(value.system_charging_power_kw[1]['average_power']);
  const {resetdata, updateresetdata} = useResetContext();



  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The average charging power is displayed by two values: maximum value during charging cycle and average value during charging cycle.

      If more than one vehicle is selected average values are displayed.
      </Popover.Body>
    </Popover>
  );

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };

  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      //console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Charging power',category:resetdata}).then(data => {
        set_charging_power(JSON.parse(data['jsonData'][0]));
        setShowMonthlyText(data['jsonData'][1]['average_power'])
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);

  useEffect(() => {
  }, [charging_power]);
  //console.log('charging_power123456',value,charging_power)


  useEffect(() => {
    try {
      set_charging_power(JSON.parse(value.system_charging_power_kw[0]));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);
  //console.log('charging_power123',value,charging_power)


  

  return (
    <div >
      <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Average charging power (system)
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>

      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      <ManRadioButtonGroup 
        name="Charging Power"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Charging Power" label="Daily" value="Daily" checked />
        <ManRadioButton name="Charging Power" label="Monthly" value="Monthly" />
        <ManRadioButton name="Charging Power" label="Yearly" value="Yearly" />
      </ManRadioButtonGroup>
      </div>
      {isLoading ? <Shimmer_graph /> : charging_power && <Plot 
        data={charging_power.data} 
        layout={{
            ...charging_power.layout,
            autosize: true,
            responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 160 }
        }}
        // style={{ width: '100%', height: '85%' }}
        useResizeHandler={true}
    />}
              <CustomTextDisplay
              title="AI | Automotive Intelligence"
              value={showMonthlyText}
              isValueStyled
            />      
      </div>
  );
};

Charging_power.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Charging_power;

