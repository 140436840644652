import { useEffect, useState } from "react";
import { couldStartTrivia } from "typescript";

// Custom hook to fetch dropdown data
const UseDropdownTrucks = () => {
  // State to store dropdown information
  const [dropdowndata, setDropdownInfo] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);


  // //console.log('in testselectedValues',selected_value);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);



  // Async function to fetch data from the server
  const fetchData = async () => {
    setIsRefreshing(true);
  
    try {
      let baseURL;
      if (window.location.hostname === "simbaanalytics-dev.rio.cloud") {
        baseURL = "https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws";
      } else if (window.location.hostname === "bev-dev.simbaanalytics-dev.rio.cloud") {
        baseURL = "https://spv3toxlwj.execute-api.eu-west-1.amazonaws.com/test_cognito";
      } else if (window.location.hostname === "bev-prod.simbaanalytics-dev.rio.cloud") {
        baseURL = "https://dnz0zln7l2.execute-api.eu-west-1.amazonaws.com/prod";
      } else {
        baseURL = "http://localhost:8080";
      }
  
      // Retrieve the ID token from localStorage
      const token = localStorage.getItem("idToken");
      
      if (!token) {
        console.error("No ID token found! User might not be authenticated.");
        return;
      }
  
      // Prepare request headers
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token as a Bearer token
      };
  
      // Make the API request with authentication
      const response = await fetch(`${baseURL}/dropdown/Trucks`, {
        method: "GET",
        headers: headers,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const jsonData = await response.json();
      setDropdownInfo(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  
    setIsRefreshing(false);
  };
  


  // //console.log('dropdownInfo',dropdownInfo)

  // Return the fetched dropdown information
  return {dropdowndata, fetchData,isRefreshing};
};

// Export the custom hook for use in other components
export default UseDropdownTrucks;
