import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import ifav from './ifav.png';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { Card } from 'react-bootstrap';

const Average_consumption_speed_class = ({ value }) => {
  const [Speed_Class_standstill_graph, set_Speed_Class_standstill_graph] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The energy throughput (unit: kWh / 100km) is displayed per speed class. Additionally, the energy throughput (unit: kWh / h) when standing (with running engine) is displayed.
      If more than one vehicle is selected average values are displayed.
      </Popover.Body>
    </Popover>
  );

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };

  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      //console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Speed Class| Standstill'}).then(data => {
        set_Speed_Class_standstill_graph(JSON.parse(data['jsonData']));
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);

  useEffect(() => {
    //console.log('testing23456', Speed_Class_standstill_graph);
  }, [Speed_Class_standstill_graph]);

  useEffect(() => {
    try {
      set_Speed_Class_standstill_graph(JSON.parse(value.standstill_consumption));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  

  return (
<Card className='card_ride' >      <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Energy throughput per speed class
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>

      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      <ManRadioButtonGroup 
        name="Speed Class| Standstill"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Energy Throughput per Speed Class" label="Yesterday" value="Yesterday" checked />
        <ManRadioButton name="Energy Throughput per Speed Class" label="Current month" value="Current month" />
        <ManRadioButton name="Energy Throughput per Speed Class" label="Current year" value="Current year" />
      </ManRadioButtonGroup>
      </div>
      {isLoading ? <Shimmer_graph /> : Speed_Class_standstill_graph && <Plot 
        data={Speed_Class_standstill_graph.data} 
        layout={{
            ...Speed_Class_standstill_graph.layout,
            autosize: true,
            responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 10 }
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
    />}    
      </Card>
  );
};

Average_consumption_speed_class.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Average_consumption_speed_class;