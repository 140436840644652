import React, { useEffect, useState } from "react";
import axios from "axios";
import Mainscreen from "./screens/main_screen";
import { initializeRUM } from "./awsRUM";
import authConfig from "./authConfig"; // Cognito OAuth2 Config
import { jwtDecode } from "jwt-decode";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    async function handleAuth() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      const expiryTime = localStorage.getItem("expiryTime");
      const refreshToken = localStorage.getItem("refreshToken");
      const currentTime = Math.floor(Date.now() / 1000);

      if (code && !localStorage.getItem("idToken")) {
        await exchangeCodeForToken(code);
        window.history.replaceState({}, document.title, window.location.pathname);
      } else if (localStorage.getItem("idToken")) {
        console.log("⏳ Checking token expiry...");
        if (expiryTime && currentTime > parseInt(expiryTime)) {
          if (refreshToken) {
            await refreshAccessToken(); // Use refresh token to get a new access token
          } else {
            console.warn("⚠️ No refresh token found, redirecting to login...");
            redirectToLogin(); // No refresh token → User must log in again
          }
        } else {
          setIsAuthenticated(true);
        }
      } else {
        redirectToLogin();
      }
    }

    handleAuth();

    if (window.location.hostname !== "localhost") {
      console.log("✅ Initializing AWS RUM...");
      initializeRUM();
    }

    // 🔄 Auto-refresh the token every 30 seconds before expiry
    const tokenRefreshInterval = setInterval(() => {
      const expiryTime = localStorage.getItem("expiryTime");
      const refreshToken = localStorage.getItem("refreshToken");
      const currentTime = Math.floor(Date.now() / 1000);

      if (expiryTime && currentTime > parseInt(expiryTime) - 60) { // Refresh 1 min before expiry
        if (refreshToken) {
          refreshAccessToken();
        } else {
          console.warn("⚠️ No refresh token found, forcing login...");
          redirectToLogin();
        }
      }
    }, 30000); // ⏳ Check every 30 seconds

    return () => clearInterval(tokenRefreshInterval); // Cleanup interval on unmount
  }, []);

  // 🔄 Redirect to Cognito Login
  const redirectToLogin = () => {
    console.log("🔄 Redirecting to login...");
    localStorage.clear();
    window.location.href = `https://${authConfig.cognitoDomain}/oauth2/authorize?client_id=${authConfig.clientId}&response_type=code&redirect_uri=${authConfig.redirectUri}&scope=openid+phone+email`;
  };

  // 🔑 Exchange Authorization Code for Token
  const exchangeCodeForToken = async (code: string): Promise<void> => {
    try {
      console.log("🔄 Exchanging code for token...");
      const response = await axios.post(
        `https://${authConfig.cognitoDomain}/oauth2/token`,
        new URLSearchParams({
          grant_type: "authorization_code",
          client_id: authConfig.clientId,
          redirect_uri: authConfig.redirectUri,
          code,
        }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      storeTokens(response.data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("🚨 Error exchanging code for token:", error);
      redirectToLogin();
    }
  };

  // 🔄 Refresh Access Token
  const refreshAccessToken = async (): Promise<void> => {
    try {
      console.log("🔄 Refreshing access token...");
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        console.warn("⚠️ Refresh token not found, redirecting to login...");
        redirectToLogin();
        return;
      }

      const response = await axios.post(
        `https://${authConfig.cognitoDomain}/oauth2/token`,
        new URLSearchParams({
          grant_type: "refresh_token",
          client_id: authConfig.clientId,
          refresh_token: refreshToken,
        }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      console.log("✅ Token refreshed:", response.data);
      storeTokens(response.data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("🚨 Error refreshing token:", error);
      redirectToLogin();
    }
  };

  // 📌 Store Tokens in LocalStorage
  const storeTokens = (data: any) => {
    const expiryTime = Math.floor(Date.now() / 1000) + data.expires_in; // Expiry in seconds
    localStorage.setItem("idToken", data.id_token);
    localStorage.setItem("accessToken", data.access_token);
    localStorage.setItem("refreshToken", data.refresh_token);
    localStorage.setItem("expiryTime", expiryTime.toString());
    console.log("✅ Tokens stored in localStorage");
    try {
      const decodedToken: any = jwtDecode(data.id_token);
      localStorage.setItem("username", decodedToken.email || decodedToken.phone_number || "User");
      console.log("✅ Decoded user info:", decodedToken);
    } catch (error) {
      console.error("🚨 Error decoding ID token:", error);
    }
    
    console.log("✅ Tokens stored in localStorage");




  };

  const username = localStorage.getItem("username") || "User";

  return isAuthenticated ? <Mainscreen props={{ username }} /> : <p>Redirecting to login...</p>;

}

export default App;
