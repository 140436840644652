import React, { useState } from 'react';
import { ManText, ManDropdownMenu,ManSelect,ManSelectItem } from "@craft-design-system/core-components-react";
import Summary from './Summary';
import Ride_Analysis from './Ride_analysis';
import BatteryAnalysis from './battery_analysis';
import ChargingAnalysis from './charging_analysis';
const TabComponent = ({initial_tab}) => {
  const [selectedTab, setSelectedTab] = useState(initial_tab);

  const tabStyle = {
    padding: '10px, 0px, 10px, 0px',
    cursor: 'pointer',
    fontWeight: 'bold',
    borderBottom: ' #d3d3d3 solid 1px',
    marginRight: '5px',
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    color:'black',
    background: 'white'
  };

  const selectedTabStyle = {
    ...tabStyle,
    borderBottom: 'red solid 2px',
    background: 'white',
    color: '#E40045'
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-around' ,backgroundColor: 'white'}}>
      <span 
          style={selectedTab === 'Summary' ? selectedTabStyle : tabStyle} 
          onClick={() => setSelectedTab('Summary')}
        >
          <ManText className="tabs1" variant={"heading-s"} as={"span"}>
            Summary
          </ManText>
        </span>
        <span 
          style={selectedTab === 'Ride Analysis' ? selectedTabStyle : tabStyle} 
          onClick={() => setSelectedTab('Ride Analysis')}
        >
          <ManText className="tabs1" variant={"heading-s"} as={"span"}>
            Ride Analysis
          </ManText>
        </span>
        <span 
          style={selectedTab === 'tab2' ? selectedTabStyle : tabStyle} 
          onClick={() => setSelectedTab('tab2')}
        >
          <ManText className="tabs1" variant={"heading-s"} as={"span"}>
            Battery
          </ManText>
        </span>
        <span 
          style={selectedTab === 'tab3' ? selectedTabStyle : tabStyle} 
          onClick={() => setSelectedTab('tab3')}
        >
          <ManText className="tabs1" variant={"heading-s"} as={"span"}>
            Charging
          </ManText>
        </span>

      </div>

      <div style={{ display: selectedTab === 'Ride Analysis' ? 'block' : 'none' }}>
        <Ride_Analysis />
      </div>
      <div style={{ display: selectedTab === 'tab2' ? 'block' : 'none' }}>
        <BatteryAnalysis />
      </div>
      <div style={{ display: selectedTab === 'tab3' ? 'block' : 'none' }}>
        <ChargingAnalysis />
      </div>
      <div style={{ display: selectedTab === 'Summary' ? 'block' : 'none' }}>
        <Summary />
      </div>
    </div>
  );
};

export default TabComponent;