import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";
import { Padding } from "@mui/icons-material";

const Mileage = ({ value }) => {
  const [mil, set_mil] = useState(null);

  useEffect(() => {
    try {
      set_mil(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <div style={{  width: '100%', height: '100%', boxSizing: 'border-box', overflow: 'hidden' }}>
      {/* <h3>Vehicle total mileage at a glance</h3> */}
            <Plot 
                data={mil?.data} 
                layout={{
                    ...mil?.layout,
                    autosize: true,
                    responsive: true,
                    // margin: { t: 50, r: 10, l: 10, b: 10 }
                }}
                style={{ width: '100%', height: '277px' }}
                useResizeHandler={true}
            />
        </div>
  );
};

Mileage.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Mileage;
