import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { Card } from 'react-bootstrap';

const MAN_part_no = ({ value }) => {
  const [man_part_no, set_man_part_no] = useState(null);


//   //console.log('test',man_part_no)






  useEffect(() => {
    try {
        set_man_part_no(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
<Card className='card_battery' >      
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      MAN part number battery
      </ManText>

      <PopoverComponent id= 'median_consumption' content='This KPI states the installed MAN part number of the battery on the vehicle. The value is taken from the vehicle configuration.

If more than one vehicle is selected the distinct number of vehicles per battery MAN part number is displayed.' />
      



      {man_part_no && <Plot 
          data={man_part_no.data} 
          layout={{
              ...man_part_no.layout,
              autosize: true,
              responsive: true,
              margin: { t: 10, r: 10, l: 10, b: 10 }
          }}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler={true}
      />}    
      </Card>
  );
};

MAN_part_no.propTypes = {
  value: PropTypes.string.isRequired,
};

export default MAN_part_no;