import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import PopoverComponent from '../popover';

const Software_stage = ({ value }) => {
  const [software_stage, set_software_stage] = useState(null);

//   //console.log('test',value)


//   //console.log('test',software_stage)






  useEffect(() => {
    try {
        set_software_stage(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
<Card className='card_battery' >      
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Battery software stage
      </ManText>
      <PopoverComponent id= 'median_consumption' content='This KPI states the installed battery software stage on the vehicle. The value is taken from the vehicle configuration.

If more than one vehicle is selected the distinct number of vehicles per battery software stage is displayed.' />



      {software_stage && <Plot 
          data={software_stage.data} 
          layout={{
              ...software_stage.layout,
              autosize: true,
              responsive: true,
              margin: { t: 10, r: 10, l: 10, b: 10 }
          }}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler={true}
      />}    
      </Card>
  );
};

Software_stage.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Software_stage;